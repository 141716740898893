import React, {FC, useEffect, useState} from 'react';
import Button from '../shared-components/button';
import {
  isValidDomain,
  isValidEmail,
  isValidFirstName,
  isValidLastName,
  isValidPhoneNumber,
  isValidUrl,
} from '../../helpers';
import {Col, Row} from 'react-bootstrap';
import Input from '../shared-components/input';
import Select from '../shared-components/select';
import TextArea from '../shared-components/textarea';
import './styles.scss';
import {checkedIcon} from '../../assets/svg-icons';

export const options: ReadonlyArray<{label: string; value: string}> = [
  {label: '1-10', value: '1-10'},
  {label: '11-50', value: '11-50'},
  {label: '51-100', value: '51-100'},
  {label: '101-200', value: '101-200'},
  {label: '201-500', value: '201-500'},
  {label: '500+', value: '500+'},
];

const EnterpriseForm: FC<{handleCloseModal: () => void}> = ({handleCloseModal}) => {
  const [invalidValues, setInvalidValues] = useState({
    workEmail: false,
    phoneNumber: false,
    website: false,
  });

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState<{
    [arg: string]: string;
  }>({
    firstName: '',
    lastName: '',
    workEmail: '',
    phoneNumber: '',
    companyName: '',
    website: '',
    message: '',
  });
  const [error, setError] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    error && setTimeout(() => setError(''), 5000);
  }, []);

  const fieldChangeHandler = (event: React.SyntheticEvent): void => {
    const target = event.target as HTMLInputElement;
    const {value, name} = target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if ((name === 'workEmail' || name === 'phoneNumber' || name === 'website') && value) {
      setError('');
      setInvalidValues({...invalidValues, [name]: false});
    }
  };

  useEffect(() => {
    const valueArr = Object.values(formData);

    valueArr.forEach((elem: string) => {
      if (elem.trim() === '') {
        setError('');
      }
    });
  }, [formData]);

  const handleSubmit = async (): Promise<any> => {
    setShowErrorMsg(false);
    if (Object.values(formData).filter((item: any) => !item.trim()).length > 0) {
      setShowErrorMsg(true);
      return;
    }

    if (!isValidEmail(formData.workEmail.trim())) {
      setShowErrorMsg(true);
      setInvalidValues({phoneNumber: false, website: false, workEmail: true});
      return;
    }
    if (!isValidPhoneNumber(formData.phoneNumber.trim())) {
      setShowErrorMsg(true);
      setInvalidValues({workEmail: false, website: false, phoneNumber: true});
      return;
    }
    if (!isValidUrl(formData.website.trim()) && !isValidDomain(formData.website.trim())) {
      setShowErrorMsg(true);
      setInvalidValues({workEmail: false, phoneNumber: false, website: true});
      return;
    }
    getExistingResult();
  };
  const getExistingResult = async () => {
    setRequestLoading(true);
    const newFormData = {...formData};
    Object.keys(newFormData).forEach((k: string) => (newFormData[k] = newFormData[k].trim()));
    try {
      const result = await fetch(`https://api.hexometer.com/v2/ql`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation {
            EnterpriseSectionOps {
                sendEmail (settings: 
                    {
                        firstName: "${newFormData.firstName}", 
                        lastName: "${newFormData.lastName}", 
                        workEmail: "${newFormData.workEmail}",
                        phoneNumber: "${newFormData.phoneNumber}",
                        companyName: "${newFormData.companyName}",
                        companySize: "-",
                        jobTitle: "-",
                        website: "${newFormData.website}",
                        message: ${JSON.stringify(newFormData.message)},
                        productType: "hexact",
                    }
                ) {
                error
                message
              }
            }
          }`,
        }),
      });
      await result
        .json()
        .then(({data}) => {
          if (data && data.EnterpriseSectionOps && data.EnterpriseSectionOps.sendEmail) {
            if (data.EnterpriseSectionOps.sendEmail.error) {
              setError(data.EnterpriseSectionOps.sendEmail.message);
              setShowErrorMsg(true);
            } else {
              setFormData({
                firstName: '',
                lastName: '',
                workEmail: '',
                phoneNumber: '',
                companyName: '',
                website: '',
                message: '',
              });
              setSuccess(true);
            }
          }
          setRequestLoading(false);
        })
        .catch(() => {
          setRequestLoading(false);
          setError('Something went wrong');
        });
    } catch (e) {
      setRequestLoading(false);
      setError('Something went wrong');
    }
  };
  return (
    <>
      {!success ? (
        <div className="enterprise-block">
          <h4 className="title">Fill out the form below and we’ll get in touch</h4>
          <Row>
            <Col xs={12} lg={6}>
              <label htmlFor="firstName">
                First name <span>*</span>
              </label>
              <Input
                placeholder="Your first name"
                onChange={fieldChangeHandler}
                value={formData.firstName}
                name="firstName"
                id="firstName"
                error={showErrorMsg && !formData.firstName.trim() ? 'error' : ''}
              />
              <div className="position-absolute error-text">
                {showErrorMsg && !isValidFirstName(formData.firstName) && formData.firstName.trim()
                  ? 'First name is not valid'
                  : ''}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <label htmlFor="lastName">
                Last name <span>*</span>
              </label>
              <Input
                placeholder="Your last name"
                onChange={fieldChangeHandler}
                value={formData.lastName}
                name="lastName"
                id="lastName"
                error={showErrorMsg && !formData.lastName.trim() ? 'error' : ''}
              />{' '}
              <div className="position-absolute error-text">
                {showErrorMsg && !isValidLastName(formData.lastName) && formData.lastName.trim()
                  ? 'Last name is not valid'
                  : ''}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <label htmlFor="workEmail">
                Work email <span>*</span>
              </label>
              <Input
                placeholder="example@company.com"
                type="email"
                id="email"
                name="workEmail"
                onChange={fieldChangeHandler}
                value={formData.workEmail}
                error={showErrorMsg && (!formData.workEmail.trim() || !isValidEmail(formData.workEmail)) ? 'error' : ''}
              />
              <div className="position-absolute error-text">
                {showErrorMsg && !isValidEmail(formData.workEmail) && formData.workEmail.trim()
                  ? 'Work email is not valid'
                  : ''}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <label htmlFor="phoneNumber">
                Phone number <span>*</span>
              </label>
              <Input
                placeholder="(555) 123-1234"
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                onChange={fieldChangeHandler}
                value={formData.phoneNumber}
                error={
                  showErrorMsg && (!formData.phoneNumber.trim() || !isValidPhoneNumber(formData.phoneNumber))
                    ? 'error'
                    : ''
                }
              />
              <div className="position-absolute error-text">
                {showErrorMsg && !isValidPhoneNumber(formData.phoneNumber) && formData.phoneNumber.trim()
                  ? 'Phone number is not valid'
                  : ''}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <label htmlFor="companyName">
                Organization name <span>*</span>
              </label>
              <Input
                placeholder="Organization name"
                type="text"
                name="companyName"
                id="companyName"
                onChange={fieldChangeHandler}
                value={formData.companyName}
                error={showErrorMsg && !formData.companyName.trim() ? 'error' : ''}
              />
            </Col>
            <Col xs={12} lg={6}>
              <label htmlFor="website">
                Website <span>*</span>
              </label>
              <Input
                placeholder="company.com"
                type="url"
                id="url"
                name="website"
                onChange={fieldChangeHandler}
                value={formData.website}
                error={showErrorMsg && (!formData.website.trim() || !isValidUrl(formData.website)) ? 'error' : ''}
              />
              <div className="position-absolute error-text">
                {showErrorMsg && !isValidUrl(formData.website) && formData.website.trim()
                  ? 'Website URL is not valid'
                  : ''}
              </div>
            </Col>

            <Col xs={12}>
              <label className="textarea-label" htmlFor="message">
                Message <span>*</span>
              </label>
              <TextArea
                value={formData.message}
                onChange={fieldChangeHandler}
                name="message"
                placeholder="Please provide an overview of your business."
                rows={6}
                disableResize
                error={showErrorMsg && !formData.message.trim()}
              />
            </Col>
          </Row>
          <div className={`error-text text-center text-bold ${showErrorMsg && error ? 'visible' : 'invisible'}`}>
            {showErrorMsg && error ? error : 'error'}
          </div>
          <div className="submit-block">
            <Button
              btnText="Submit"
              loading={requestLoading}
              disabled={requestLoading}
              onClick={handleSubmit}
              width="180"
              primary
            />
          </div>
        </div>
      ) : (
        <div className="p-0 text-center">
          <span className="checked-icon">{checkedIcon}</span>
          <p className="pt-4 m-0 modal-text">Your message has been successfully sent!</p>
          <p className="p-0 m-0 modal-text">Thank you!</p>
          <div className="col-12 text-center mt-4">
            <Button btnText="OK" width="230" onClick={handleCloseModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default EnterpriseForm;

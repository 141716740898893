import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Col, Row} from 'react-bootstrap';
import atob from 'atob';

import Layout from '../../components/layout';
import {Modal} from '../../components/modal';
import EnterpriseForm from '../../components/enterprise-form';
import Button from '../../components/shared-components/button';
import {closeIcon} from '../../assets/svg-icons';

import {forInvestorsBase64} from '../../assets/pagehero-base64/for-investors-base64';
import roundsMobile from '../../assets/for-investors/rounds-mobile.png';
import rounds from '../../assets/for-investors/rounds.png';

import sada from '../../assets/for-investors/round-icons/sada.svg';
import arrow from '../../assets/for-investors/round-icons/arrow.svg';
import rebrand from '../../assets/for-investors/round-icons/rebrand.svg';
import users from '../../assets/for-investors/round-icons/reg-users.svg';
import preseed from '../../assets/for-investors/round-icons/pre-seed.svg';
import hexospark from '../../assets/for-investors/round-icons/hexospark.svg';
import hexometer from '../../assets/for-investors/round-icons/l-hexometer.svg';
import hexowatch from '../../assets/for-investors/round-icons/l-hexowatch.svg';
import hexomatic from '../../assets/for-investors/round-icons/l-hexomatic.svg';
import hexofy from '../../assets/for-investors/round-icons/launched-hexofy.svg';
import revenue from '../../assets/for-investors/round-icons/million-revenue.svg';
import startEngine from '../../assets/for-investors/round-icons/start-engine.svg';
import usersIncrease from '../../assets/for-investors/round-icons/usersIncrease.svg';

import './styles.scss';

const Investors = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const growth = [
    {img: hexometer, period: 'Mar 2019', text: 'Launched Hexometer'},
    {img: hexowatch, period: 'July 2020', text: 'Launched Hexowatch'},
    {img: users, period: 'Jun 2021', text: '20K+ registered users total'},
    {img: hexomatic, period: 'July 2021', text: 'Launched Hexomatic'},
    {img: sada, period: 'Oct 2021', text: 'Partnered with Google Cloud, SADA'},
    {img: preseed, period: 'Dec 2021', text: 'Pre-seed round'},
    {img: rebrand, period: 'Mar 2022', text: 'Hexact Rebrand'},
    {img: usersIncrease, period: 'Jul 2022', text: '100K+ registered users total'},
    {img: hexospark, period: 'Sep 2022', text: 'Launched Hexospark'},
    {img: revenue, period: 'Dec 2022', text: '$1M revenue milestone'},
    {img: hexofy, period: 'Mar 2023', text: 'Launched Hexofy'},
    {img: startEngine, period: 'Apr 2023', text: 'StartEngine crowdfunding round'},
  ];

  const isMobileLg = useMediaQuery({maxWidth: 991, minWidth: 767});
  const isMobileSm = useMediaQuery({maxWidth: 767});

  return (
    <Layout
      pageheroColor="#4262FF"
      pageheroText="Together we bring Hexact to the next level."
      pageheroTitle={
        <>
          <div>Invest in</div>
          <div>The No-code movement</div>
        </>
      }
      pageheroImg={atob(forInvestorsBase64)}
      className="for-investors-page"
    >
      <div className="text-center mx-auto">
        <div className="secondary-text" style={{maxWidth: '660px', margin: 'auto', padding: '0 30px'}}>
          As we move forward in our journey to making business intelligence solutions more available at every scale for
          companies worldwide, we invite investors who share our vision to join us along the way and become part of our
          success at Hexact.
        </div>
        <div className="d-flex  flex-wrap rounds-container">
          {growth.map((item, index) => (
            <div
              className={`round-item ${
                index === 11
                  ? 'round-item-sm'
                  : index === 5 && !isMobileLg && !isMobileSm
                  ? 'round-item-sm'
                  : isMobileSm && index % 2 !== 0
                  ? 'round-item-sm'
                  : ''
              }`}
            >
              <div className="round-item-div">
                <img src={item.img} alt={item.period} className="inv-icon" />
                <h3>{item.period}</h3>
                <p>{item.text}</p>
              </div>
              <div className="arrow-section">
                <img
                  className={`arrow ${
                    index === 11
                      ? 'd-none'
                      : index === 5 && !isMobileLg && !isMobileSm
                      ? 'd-none'
                      : isMobileLg && (index === 2 || index === 5 || index === 8)
                      ? 'd-block d-md-none'
                      : isMobileSm && index % 2 !== 0
                      ? 'd-none'
                      : ''
                  }`}
                  src={arrow}
                  alt="arrow"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="pitch-deck-container">
          <h5 className="pt-1">Interested?</h5>
          <div className="mt-3 mx-auto text-center">
            {' '}
            <Button btnText="Investment Opportunities" onClick={handleClick} primary width="426" />
          </div>
        </div>
      </div>
      {openModal && (
        <Modal isOpen={openModal} modalToggle={() => {}} modalFooter={<></>} className="enterprise-modal">
          <span className="position-absolute close-icon" onClick={handleCloseModal}>
            {closeIcon}
          </span>
          <EnterpriseForm handleCloseModal={handleCloseModal} />
        </Modal>
      )}
    </Layout>
  );
};

export default Investors;
